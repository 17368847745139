.hide {
    visibility: hidden;
}

.display-none {
  display: none;
}

.slider-card {
  padding: 2px;
}

b {
  font-family: 'DMSans-SemiBold';
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-inline-start: inherit;
  margin-inline-end: inherit;
  font-weight: inherit;
  unicode-bidi: inherit;
}

.loader-center {
    margin-bottom: 250px;
    margin-top: 150px;
    text-align: center;
    width: 100% !important;
}

.ticker-wrap {
  width: 100%;
  margin-top: 30px;
  overflow: hidden;
  white-space: nowrap;
}
.ticker {
  display: inline-block;
  margin-top: 5px;
  animation: marquee 180s linear infinite;
}
.item-collection-1 {
  position: relative;
  left: 0%;
  animation: swap 180s linear infinite;
}

.item {
  display: inline-block;
  height: 40px;
  margin-right: 120px;
  @media (max-width: 600px) {
    margin-right: 80px;
  }
}

/* Transition */
@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}

.animated-background {
  background-size: 600% !important;
  animation: background-animation 8s linear infinite;
}

@keyframes background-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a {
  color: inherit;
}

.ghost-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    left: -100%;
    background-image: linear-gradient(
      to right,
      rgba(white, 0) 33.3%,
      rgba(white, 0.8),
      rgba(white, 0) 66.6%
    );
    animation: shimmer 2s infinite linear;
  }
}

@keyframes shimmer {
  from {
    transform: translateX(-33.3%);
  }
  to {
    transform: translateX(33.3%);
  }
}